import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { Client, ClientFormValues } from "../models/client.ts";
import { PaginatedResults } from "../models/pagination.ts";
import {
  IUser,
  ILoginFormValues,
  AppUser,
  AppUserFormValues,
} from "../models/user.ts";
import { store } from "../stores/store.ts";
import { Router } from "../router/Router.tsx";
import {
  Manufacturer,
  ManufacturerFormValues,
} from "../models/manufacturer.ts";
import { Category, CategoryFormValues } from "../models/category.ts";
import { Product } from "../models/product.ts";
import { Order, OrderStatus } from "../models/order.ts";
import { OrderItem } from "../models/orderItem.ts";
import { Province } from "../models/province.ts";
import { Tax, TaxFormValues } from "../models/tax.ts";
import { TaxMapping, TaxMappingFormValues } from "../models/taxMapping.ts";
import { ProductLot, CreateProductLotDto } from "../models/ProductLot.ts";
import { OrderItemLot } from "../models/orderItemLot.ts";
import { OrderDetail } from "../models/orderDetail.ts";
import { LotAssignment } from "../models/lotAssignment";
import {
  DashboardStats,
  TopProductDto,
  SalesDashboardDto,
} from "../models/dashboard.ts";
import {
  InventoryStats,
  LowStockProductDto,
  ExpiringProductDto,
} from "../models/inventory.ts";
import { InventoryThresholds } from "../models/inventory.ts";
import { UserDashboardStats } from "../models/userDashboardStats.ts";
import { Return, ReturnFormValues } from "../models/return.ts";

//NIKOLAI : MOVED THE BASE URL IN ENV CONFIGURATION TO AUTOMATICALLY
//POINT TO THE THE RIGHT ENDPOINT AT DEPLOYMENT.
axios.defaults.baseURL = process.env.REACT_APP_API_ROOT;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    const pagination = response.headers["pagination"];
    if (pagination) {
      response.data = new PaginatedResults(
        response.data,
        JSON.parse(pagination)
      );
      return response as AxiosResponse<PaginatedResults<any>>;
    }
    return response;
  },
  (error: AxiosError) => {
    const { data, status, config } = error.response as AxiosResponse;

    switch (status) {
      case 400:
        if (config.method === "get" && data.errors.hasOwnProperty("id")) {
          Router.navigate("/not-found");
        }
        if (data.errors) {
          const modalStateErrors: string[] = []; // Define modalStateErrors as an array of strings
          for (const key in data.errors) {
            if (data.errors[key]) modalStateErrors.push(data.errors[key]);
          }
          throw modalStateErrors.flat();
        } else toast.error(data);
        break;
      case 401:
        toast.error("unauthorized");
        break;
      case 403:
        toast.error("forbidden");
        break;
      case 404:
        Router.navigate("/not-found");
        break;
      case 500:
        store.commonStore.setServerError(data);
        Router.navigate("/server-error");
        break;
    }

    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
  toggleActive: <T>(url: string) => axios.put<T>(url).then(responseBody),
};
//@riniyad included province tax and taxmapping endpoints
const provinceUrl = "/provinces";
const Provinces = {
  list: () => axios.get<Province[]>(provinceUrl).then(responseBody),
};

const taxURL = "/taxes";
const Taxes = {
  list: (params: URLSearchParams) =>
    axios.get<PaginatedResults<Tax[]>>(taxURL, { params }).then(responseBody),
  details: (id: number) => requests.get<Tax>(taxURL + "/" + id),
  create: (tax: TaxFormValues) => requests.post<void>(taxURL, tax),
  edit: (tax: TaxFormValues) => requests.put<void>(taxURL + "/" + tax.id, tax),
  delete: (id: number) => requests.del<void>(taxURL + "/" + id),
};

const taxMappingURL = "/taxMappings";
const TaxMappings = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResults<TaxMapping[]>>(taxMappingURL, { params })
      .then(responseBody),
  details: (id: number) => requests.get<TaxMapping>(taxMappingURL + "/" + id),
  create: (taxMapping: TaxMappingFormValues) =>
    requests.post<void>(taxMappingURL, taxMapping),
  edit: (taxMapping: TaxMappingFormValues) =>
    requests.put<void>(taxMappingURL + "/" + taxMapping.id, taxMapping),
  delete: (id: number) => requests.del<void>(taxMappingURL + "/" + id),
};

const clientURL = "/clients";
const Clients = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResults<Client[]>>(clientURL, { params })
      .then(responseBody),
  details: (id: number) => requests.get<Client>(clientURL + "/" + id),
  create: (client: ClientFormValues) => requests.post<void>(clientURL, client),
  edit: (client: ClientFormValues) =>
    requests.put<void>(clientURL + "/" + client.id, client),
  delete: (id: number) => requests.del<void>(clientURL + "/" + id),

  toggleActive: (id: number) =>
    requests.toggleActive<void>(clientURL + "/toggle/" + id),
};

const manufacturerURL = "/manufacturers";
const Manufacturers = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResults<Manufacturer[]>>(manufacturerURL, { params })
      .then(responseBody),
  details: (id: number) =>
    requests.get<Manufacturer>(manufacturerURL + "/" + id),
  create: (manufacturer: ManufacturerFormValues) =>
    requests.post<void>(manufacturerURL, manufacturer),
  edit: (manufacturer: ManufacturerFormValues) =>
    requests.put<void>(manufacturerURL + "/" + manufacturer.id, manufacturer),
  delete: (id: number) => requests.del<void>(manufacturerURL + "/" + id),
  toggleActive: (id: number) =>
    requests.toggleActive<void>(manufacturerURL + "/toggle/" + id),
};

const categoryURL = "/category";
const Categories = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResults<Category[]>>(categoryURL, { params })
      .then(responseBody),
  details: (id: number) => requests.get<Category>(categoryURL + "/" + id),
  create: (category: CategoryFormValues) =>
    requests.post<void>(categoryURL, category),
  edit: (category: CategoryFormValues) =>
    requests.put<void>(categoryURL + "/" + category.id, category),
  delete: (id: number) => requests.del<void>(categoryURL + "/" + id),
  toggleActive: (id: number) =>
    requests.toggleActive<void>(categoryURL + "/toggle/" + id),
};

const productUrl = "/products";
const Products = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResults<Product[]>>(productUrl, { params })
      .then(responseBody),
  details: (id: number) => requests.get<Product>(productUrl + "/" + id),
  create: (formData: FormData) => requests.post<void>(productUrl, formData),
  edit: (id, formData: FormData) =>
    requests.put<void>(productUrl + "/" + id, formData),
  delete: (id: number) => requests.del<void>(productUrl + "/" + id),
  toggleActive: (id: number) =>
    requests.toggleActive<void>(productUrl + "/toggle/" + id),
};

const userUrl = "/users";
const AppUsers = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResults<AppUser[]>>(userUrl, { params })
      .then(responseBody),
  details: (userName: string) =>
    requests.get<AppUser>(userUrl + "/" + userName),
  create: (user: AppUserFormValues) => requests.post<void>(userUrl, user),
  edit: (user: AppUserFormValues) =>
    requests.put<void>(userUrl + "/" + user.userName, user),
  // delete: (userName: string) => requests.del<void>(userUrl + "/" + userName),
  toggleActive: (userName: string) =>
    requests.toggleActive<void>(userUrl + "/toggle/" + userName),
  getUserByUsername: (username: string) =>
    requests.get<AppUser>(`${userUrl}/${username}`),
};

const currentAppUserUrl = "/user";
const currentAppUser = {
  details: (userName: string) =>
    requests.get<AppUser>(currentAppUserUrl + "/" + userName),

  edit: (user: AppUserFormValues) =>
    requests.put<void>(currentAppUserUrl + "/" + user.userName, user),
  // delete: (userName: string) => requests.del<void>(userUrl + "/" + userName),

  getUserByUsername: (username: string) =>
    requests.get<AppUser>(`${currentAppUserUrl}/${username}`),
};

const orderUrl = "/orders";
const Orders = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResults<Order[]>>(orderUrl, { params })
      .then(responseBody),
  itemsList: (params: URLSearchParams) =>
    axios
      .get<PaginatedResults<OrderItem[]>>(orderUrl + "/items", { params })
      .then(responseBody),
  myOrdersList: (params: URLSearchParams) =>
    axios
      .get<PaginatedResults<Order[]>>(orderUrl + "/myorders", { params })
      .then(responseBody),
  details: (id: number) => requests.get<Order>(orderUrl + "/" + id),
  create: (order: Order) => requests.post<void>(orderUrl, order),
  toggleActive: (id: number) =>
    requests.toggleActive<void>(orderUrl + "/approve/" + id),
  //changes done by riniyad : Change request : Tony
  //Funtionality Added : Admin Can Toggle between Approval and Rejection of Orders,deletion
  //revoke method defination in your agent object.sends a PUT request to the backend API.
  //Delete method defination in your agent object.sends a post request to the backend API.
  //updateOrderItem: Defination; Admin And User Can edit orderlist- if isApproved=false
  revoke: (id: number) => requests.put<void>(orderUrl + "/revoke/" + id, {}),
  delete: (id: number) => requests.del(`/orders/${id}`),
  updateOrderItem: (orderItem: OrderItem) =>
    requests.put<void>(
      `${orderUrl}/items/${orderItem.id}/${orderItem.quantity}`,
      {}
    ),
  deleteOrderItem: (orderId: number, orderItemId: number) =>
    requests.del(`/orders/${orderId}/items/${orderItemId}`),
  addOrderItems: (orderId: number, orderItems: OrderItem[]) =>
    requests.post(`/orders/${orderId}/items`, orderItems),
  updateOrderStatus: (orderId: number, status: OrderStatus) =>
    requests.put<void>(`${orderUrl}/updateOrderStatus/${orderId}`, { status }),
  assignLots: (orderId: number) =>
    requests.post<OrderItemLot[]>(`/orders/${orderId}/assign-lots`, {}),
  getOrderItemsWithLots: (id: number) =>
    requests.get<OrderDetail[]>(orderUrl + "/OrderItemLot/" + id),
  updateOrderItemLots: (orderItemId: number, lotAssignments: LotAssignment[]) =>
    requests.put(`/orders/items/${orderItemId}/lots`, lotAssignments),
  updateLotPackedStatus: (
    orderItemId: number,
    lotId: number,
    packed: boolean
  ) =>
    requests.put(`/orders/items/${orderItemId}/lots/${lotId}/packed`, {
      packed,
    }),
  updateShipmentDate: (orderId: number, shipmentDate: Date) =>
    requests.put(`/orders/${orderId}/shipment-date`, { shipmentDate }),
  checkOrderStock: (orderId: number) =>
    requests.get<any>(`${orderUrl}/${orderId}/stock-check`),
};

const Account = {
  current: () => requests.get<IUser>("/account"),
  login: (user: ILoginFormValues) =>
    requests.post<IUser>("/account/login", user),
  // register: (user: UserFormValues) =>
  //   requests.post<User>('/account/register', user),
};
const productLotUrl = "/ProductLot";
const ProductLots = {
  list: () => requests.get<ProductLot[]>(productLotUrl),
  details: (id: number) => requests.get<ProductLot>(`${productLotUrl}/${id}`),
  create: (productLots: CreateProductLotDto[]) =>
    requests.post<void>(productLotUrl, productLots),
  update: (id: number, productLot: ProductLot) =>
    requests.put<void>(`${productLotUrl}/${id}`, productLot),
  delete: (id: number) => requests.del<void>(`${productLotUrl}/${id}`),
  getByProductNumber: (productNumber: number) =>
    requests.get<ProductLot[]>(`${productLotUrl}/product/${productNumber}`),
  searchProductByName: (query: String) =>
    requests.get<Product[]>(`${productLotUrl}/productSearch/${query}`),
  checkOrderItemAssociations: (id: number): Promise<boolean> =>
    requests.get(`${productLotUrl}/${id}/has-order-items`),
  checkLotExists: (productId: number, lotNumber: string): Promise<boolean> =>
    requests.get<boolean>(
      `${productLotUrl}/exists?productId=${productId}&lotNumber=${lotNumber}`
    ),
  batchUpdate: (productLots: ProductLot[]) =>
    requests.put<void>(`${productLotUrl}/batch`, productLots),
};

const Dashboard = {
  getStats: (days: number) =>
    requests.get<DashboardStats>(`/dashboard/stats?days=${days}`),
  getTopProducts: (days: number, limit: number = 5) =>
    requests.get<TopProductDto[]>(
      `/dashboard/top-products?days=${days}&limit=${limit}`
    ),
  getInventoryStats: (timeframe: number) =>
    requests.get<InventoryStats>(
      `/dashboard/inventory/alerts?timeframe=${timeframe}`
    ),
  getLowStockProducts: (limit: number) =>
    requests.get<LowStockProductDto[]>(
      `/dashboard/inventory/low-stock?limit=${limit}`
    ),
  getExpiringProducts: (days: number) =>
    requests.get<ExpiringProductDto[]>(
      `/dashboard/inventory/expiring?days=${days}`
    ),
  getSalesDashboard: (days: number) =>
    requests.get<SalesDashboardDto>(`/dashboard/sales?days=${days}`),
  getInventoryThresholds: () =>
    requests.get<InventoryThresholds>("/dashboard/inventory/thresholds"),
  getDashboardStats: () =>
    requests.get<UserDashboardStats>("/dashboard/user-stats"),
};

const Returns = {
  list: (params: URLSearchParams) =>
    axios.get<PaginatedResults<Return[]>>('/returns', { params }).then(responseBody),
  details: (id: number) => requests.get<Return>(`/returns/${id}`),
  create: (returnData: ReturnFormValues) => requests.post<void>('/returns', returnData),
  updateStatus: (id: number, status: ReturnStatus) =>
    requests.put<void>(`/returns/${id}/status`, { status }),
  process: (id: number) => requests.post<void>(`/returns/${id}/process`, {}),
};

const agent = {
  Clients,
  Manufacturers,
  Categories,
  Products,
  AppUsers,
  Orders,
  Account,
  Provinces,
  currentAppUser,
  Taxes,
  ProductLots,
  TaxMappings: TaxMappings,
  Dashboard,
  Returns,
};

export default agent;
