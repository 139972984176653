import { styled } from "@mui/system";
import {
  Container,
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress
} from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import React from "react";
import MyTextInput from "../../app/common/form/MyTextInput.tsx";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store.ts";

const StyledPaper = styled(Paper)({
  padding: "48px",
  width: 450,
  margin: "auto",
  borderRadius: "20px",
  boxShadow: "0 8px 40px rgba(0, 0, 0, 0.12)",
  background: "rgba(255, 255, 255, 0.98)",
});

const StyledTypography = styled(Typography)({
  marginBottom: '4px',
  fontWeight: 600,
  textAlign: 'center',
  color: '#1d1d1f',
  fontSize: '2rem',
  letterSpacing: '-0.022em',
  fontFamily: '-apple-system, BlinkMacSystemFont, "SF Pro Display", "SF Pro Text", system-ui, sans-serif',
});

const SubTitle = styled(Typography)({
  marginBottom: '40px',
  textAlign: 'center',
  color: '#86868b',
  fontSize: '1.1rem',
  letterSpacing: '-0.016em',
  fontFamily: '-apple-system, BlinkMacSystemFont, "SF Pro Text", system-ui, sans-serif',
});

const StyledForm = styled(Form)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});

const StyledButton = styled(Button)({
  marginTop: "32px",
  padding: "14px",
  fontSize: "1.1rem",
  fontWeight: 600,
  borderRadius: "10px",
  textTransform: "none",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-1px)",
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.15)",
  },
  "&:disabled": {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    color: "rgba(0, 0, 0, 0.26)"
  },
});

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  minWidth: "100vw",
  background: "linear-gradient(135deg, #f6f9fc 0%, #edf2f7 100%)",
  margin: 0,
  padding: "24px",
  position: "fixed",
  top: 0,
  left: 0,
});

const LogoContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '32px',
});

const Logo = styled('img')({
  height: '44px',
  width: 'auto',
  marginBottom: '24px',
});

const LoginForm = () => {
  const { userStore } = useStore();
  const initialValues = {
    userName: "",
    password: "",
    //role: "user",
    error: null,
  };

  const validationSchema = Yup.object().shape({
    userName: Yup.string().required("UserName is required"),
    password: Yup.string().required("Password is required"),
    //role: Yup.string().required("Role is required"),
  });

  return (
    <StyledBox>
      <Container maxWidth="sm">
        <StyledPaper elevation={0}>
          <LogoContainer>
            <Logo 
              src={`${process.env.PUBLIC_URL}/LOGO_2048x.png`}
              alt="Company Logo"
            />
          </LogoContainer>
          <StyledTypography variant="h4">
            Enterprise Sales Portal
          </StyledTypography>
          <SubTitle variant="body1">
            Sign in to access your dashboard
          </SubTitle>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setErrors }) => {
              try {
                await userStore.login(values);
              } catch (error) {
                setErrors({ error: "Invalid credentials. Please try again." });
              }
            }}
          >
            {({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
              <StyledForm onSubmit={handleSubmit} autoComplete="off">
                <MyTextInput 
                  name="userName" 
                  label="Username" 
                  fullWidth
                  placeholder="Enter your username"
                  // InputLabelProps={{ shrink: true }}
                  InputProps={{
                    sx: {
                      borderRadius: "10px",
                      backgroundColor: "rgba(0, 0, 0, 0.02)",
                      '&:hover': {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      }
                    }
                  }}
                />
                <MyTextInput
                  name="password"
                  label="Password"
                  type="password"
                  fullWidth
                  placeholder="Enter your password"
                  // InputLabelProps={{ shrink: true }}
                  InputProps={{
                    sx: {
                      borderRadius: "10px",
                      backgroundColor: "rgba(0, 0, 0, 0.02)",
                      '&:hover': {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      }
                    }
                  }}
                />
                <ErrorMessage
                  name="error"
                  render={() => (
                    <Box
                      sx={{
                        border: "1px solid rgba(255, 77, 77, 0.5)",
                        borderRadius: "10px",
                        padding: "14px",
                        backgroundColor: "rgba(255, 77, 77, 0.05)",
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Typography color="error" variant="body2">
                        {errors.error}
                      </Typography>
                    </Box>
                  )}
                />
                <StyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || !dirty || !isValid}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  ) : (
                    "Sign In"
                  )}
                </StyledButton>
              </StyledForm>
            )}
          </Formik>
        </StyledPaper>
      </Container>
    </StyledBox>
  );
};

export default observer(LoginForm);
