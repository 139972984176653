import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store.ts";
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { AppUserFormValues } from "../../app/models/user.ts";
import { Link } from "react-router-dom";
// Import your store hook

const ProfilePage = observer(() => {
  const { userStore } = useStore();
  const userName = userStore.user?.userName;
  const userRole = userStore.user?.role;
  console.log(userRole);

  useEffect(() => {
    if (userName) {
      userStore.getUserProfile(userName); // Fetch the profile data
    }
  }, [userName, userStore]);

  if (!userStore.profileUser) {
    return <div>Loading profile...</div>;
  }

  const { displayName, email, bio } = userStore.profileUser;

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        User Details
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                Full Name:{displayName}
              </Typography>

              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                Email:{email}
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                Role:{userRole}
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                Bio:{bio}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={6} sx={{ marginTop: 1 }}>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                component={Link}
                to={
                  userRole === "Admin"
                    ? `/admin/user/${userName}`
                    : `/user/${userName}`
                }
              >
                Edit
              </Button>
            </Grid>

            <Grid item></Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
});

export default ProfilePage;
