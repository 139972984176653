import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Pagination,
  Box,
  CircularProgress,
  Chip,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

const ManufacturerList: React.FC = () => {
  const { manufacturerStore } = useStore();
  const {
    setPagingParams,
    deleteManufacturer,
    loadManufacturers,
    toggleActive,
    pagination,
    manufacturers,
    pagingParams,
  } = manufacturerStore;

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [manufacturerToDelete, setManufacturerToDelete] = React.useState<number | null>(null);
  const [loading, setLoading] = React.useState({
    delete: false,
    toggle: false,
  });

  const navigateToHome = (pageNumber: number) => {
    setPagingParams({
      pageNumber: pageNumber,
      pageSize: pagingParams.pageSize,
    });
    loadManufacturers();
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => navigateToHome(value);

  const handleDeleteClick = (id: number) => {
    setManufacturerToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (manufacturerToDelete) {
      setLoading({ ...loading, delete: true });
      await deleteManufacturer(manufacturerToDelete);
      await navigateToHome(1);
      setLoading({ ...loading, delete: false });
      setDeleteDialogOpen(false);
    }
  };

  const handleToggle = async (id: number) => {
    setLoading({ ...loading, toggle: true });
    await toggleActive(id);
    await navigateToHome(1);
    setLoading({ ...loading, toggle: false });
  };

  useEffect(() => {
    loadManufacturers();
  }, [loadManufacturers]);

  if (manufacturerStore.loadingInitial) return <CircularProgress />;

  if (!manufacturers || manufacturers.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography variant="h6" color="textSecondary">
          No manufacturers found
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <TableContainer component={Paper} sx={{ mb: 2, boxShadow: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="manufacturers table">
          <TableHead sx={{ bgcolor: "#525252" }}>
            <TableRow>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Description
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Active
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Edit
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Remove
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Details
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Toggle
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {manufacturers.map((manufacturer) => (
              <TableRow key={manufacturer.id} sx={{
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
                transition: "background-color 0.2s",
              }}>
                <TableCell>{manufacturer.name}</TableCell>
                <TableCell>{manufacturer.description}</TableCell>
                <TableCell>
                  <Chip 
                    label={manufacturer.isActive ? "Active" : "Inactive"}
                    color={manufacturer.isActive ? "success" : "default"}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title="Edit manufacturer">
                    <IconButton component={Link} to={`edit/${manufacturer.id}`}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Delete manufacturer">
                    <IconButton 
                      onClick={() => handleDeleteClick(manufacturer.id)}
                      disabled={loading.delete}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="View details">
                    <IconButton component={Link} to={`${manufacturer.id}`}>
                      <OpenInNewIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="Toggle active status">
                    <IconButton 
                      onClick={() => handleToggle(manufacturer.id)}
                      disabled={loading.toggle}
                    >
                      {loading.toggle ? <CircularProgress size={24} /> : <SwapHorizIcon />}
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this manufacturer?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error" 
            disabled={loading.delete}
          >
            {loading.delete ? <CircularProgress size={24} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>

      <Box mt={2} display="flex" justifyContent="flex-end">
        <Pagination
          count={pagination ? pagination.totalPages : 0}
          page={pagination ? pagination.currentPage : 1}
          onChange={handlePageChange}
        />
      </Box>
    </div>
  );
};

export default observer(ManufacturerList);