import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Pagination,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Chip,
  Stack,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import AddIcon from "@mui/icons-material/Add";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../app/stores/store.ts";
import { Link } from "react-router-dom";
import { Product } from "../../../../app/models/product";
import { Route, Routes } from "react-router-dom";
import InventoryProductDetails from "./ProductDetails.tsx";

const InventoryControl: React.FC = () => {
  const { productStore } = useStore();
  const {
    setPagingParams,
    products,
    pagination,
    loadProducts,
    updateProduct,

    loadActiveCategories,
    loadActiveManufacturers,
    setNameFilter,
    setIsActiveFilter,
    setCategoryIdFilter,
    setManufacturerIdFilter,
    deleteProduct,
    pagingParams,
    toggleActive,
  } = productStore;

  const [editingId, setEditingId] = useState<number>();
  const [editedProduct, setEditedProduct] = useState<Product>();

  const [nameFilter, setLocalNameFilter] = useState("");
  const [isActiveFilter, setLocalIsActiveFilter] = useState(true);
  const [categoryIdFilter, setLocalCategoryIdFilter] = useState("");
  const [manufacturerIdFilter, setLocalManufacturerIdFilter] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [productToDelete, setProductToDelete] = React.useState<number | null>(
    null
  );
  const [loading, setLoading] = React.useState({
    delete: false,
    toggle: false,
  });
  const navigateToHome = (pageNumber: number) => {
    setPagingParams({
      pageNumber: pageNumber,
      pageSize: pagingParams.pageSize,
    });
    loadProducts();
  };

  useEffect(() => {
    productStore.reset();

    loadProducts();
    loadActiveCategories();
    loadActiveManufacturers();

    return () => {
      productStore.reset();
    };
  }, [
    loadProducts,
    loadActiveCategories,
    loadActiveManufacturers,
    productStore,
  ]);
  const handleConfirmDelete = async () => {
    if (!productToDelete) return;
    setLoading((prev) => ({ ...prev, delete: true }));
    await deleteProduct(productToDelete);
    await navigateToHome(1);
    setLoading((prev) => ({ ...prev, delete: false }));
    setDeleteDialogOpen(false);
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPagingParams({
      pageNumber: value,
      pageSize: pagination?.itemsPerPage || 10,
    });
    loadProducts();
  };

  const handleDelete = (id: number) => {
    setProductToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleToggle = (id: number) => {
    toggleActive(id).then(() => loadProducts());
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalNameFilter(event.target.value);
    setNameFilter(event.target.value);
    loadProducts();
  };

  const handleIsActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalIsActiveFilter(event.target.checked);
    setIsActiveFilter(event.target.checked);
    loadProducts();
  };

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    setLocalCategoryIdFilter(event.target.value);
    setCategoryIdFilter(event.target.value);
    loadProducts();
  };

  const handleManufacturerChange = (event: SelectChangeEvent<string>) => {
    setLocalManufacturerIdFilter(event.target.value);
    setManufacturerIdFilter(event.target.value);
    loadProducts();
  };

  const handleEdit = (product: Product) => {
    setEditingId(product.id);
    setEditedProduct({ ...product });
  };

  const handleSave = async () => {
    if (!editedProduct) return;

    try {
      const formData = new FormData();
      Object.keys(editedProduct).forEach((key) => {
        if (key !== "category" && key !== "manufacturer" && key !== "image") {
          formData.append(key, editedProduct[key]);
        }
      });

      if (editedProduct.image) {
        formData.append("image", editedProduct.image);
      }

      await updateProduct(editedProduct.id, formData);
      setEditingId(undefined);
      setEditedProduct(undefined);
      loadProducts();
    } catch (error) {
      console.error("Failed to save product:", error);
    }
  };

  const handleCancel = () => {
    setEditingId(undefined);
    setEditedProduct(undefined);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (editedProduct) {
      setEditedProduct({ ...editedProduct, [name]: value });
    }
  };

  if (productStore.loadingInitial) return <CircularProgress />;

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Box sx={{ p: 2 }}>
            <Paper
              elevation={1}
              sx={{
                p: 2,
                mb: 3,
                borderRadius: 2,
                bgcolor: "background.paper",
              }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label="Search by name or code"
                    value={nameFilter}
                    onChange={handleNameChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isActiveFilter}
                        onChange={handleIsActiveChange}
                      />
                    }
                    label="Active"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl size="small" fullWidth>
                    <InputLabel>Manufacturer</InputLabel>
                    <Select
                      value={manufacturerIdFilter}
                      onChange={handleManufacturerChange}
                      label="Manufacturer"
                    >
                      <MenuItem value="">All</MenuItem>
                      {productStore.manufacturers?.map((manufacturer) => (
                        <MenuItem key={manufacturer.id} value={manufacturer.id}>
                          {manufacturer.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl size="small" fullWidth>
                    <InputLabel>Category</InputLabel>
                    <Select
                      value={categoryIdFilter}
                      onChange={handleCategoryChange}
                      label="Category"
                    >
                      <MenuItem value="">All</MenuItem>
                      {productStore.categories?.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    component={Link}
                    to="/admin/warehouse/inventory-control/create"
                    variant="contained"
                    startIcon={<AddIcon />}
                    fullWidth
                    sx={{
                      bgcolor: "primary.main",
                      "&:hover": {
                        bgcolor: "primary.dark",
                      },
                      fontWeight: "bold",
                      height: "40px", // Match height with other inputs
                    }}
                  >
                    Add Product
                  </Button>
                </Grid>
              </Grid>
            </Paper>

            <Paper
              elevation={2}
              sx={{
                borderRadius: 2,
                overflow: "hidden",
              }}
            >
              <TableContainer>
                <Table sx={{ minWidth: 650 }} size="medium">
                  <TableHead>
                    <TableRow
                      sx={{
                        bgcolor: (theme) => theme.palette.primary.main,
                      }}
                    >
                      <TableCell sx={{ width: "10%" }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Image
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: "25%" }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Product Details
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: "15%" }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Code
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: "20%" }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Pricing
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: "15%" }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Category
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: "15%" }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Actions
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: "15%" }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Inventory
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((product) => (
                      <TableRow
                        key={product.id}
                        sx={{
                          "&:hover": {
                            bgcolor: "action.hover",
                            transition: "background-color 0.2s ease",
                          },
                          borderBottom: "1px solid",
                          borderColor: "divider",
                        }}
                      >
                        <TableCell>
                          <Box
                            sx={{
                              width: 60,
                              height: 60,
                              borderRadius: 1,
                              overflow: "hidden",
                              border: "1px solid",
                              borderColor: "divider",
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_API_ROOT}${product.imageUrl}`}
                              alt={product.name}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          {editingId === product.id ? (
                            <TextField
                              name="name"
                              value={editedProduct?.name || ""}
                              onChange={handleChange}
                              size="small"
                              fullWidth
                              sx={{ mb: 1 }}
                            />
                          ) : (
                            <Box>
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: "medium" }}
                              >
                                {product.name}
                              </Typography>
                              <Chip
                                size="small"
                                label={product.isActive ? "Active" : "Inactive"}
                                color={product.isActive ? "success" : "default"}
                                sx={{ mt: 0.5 }}
                              />
                            </Box>
                          )}
                        </TableCell>
                        <TableCell>
                          {editingId === product.id ? (
                            <TextField
                              name="code"
                              value={editedProduct?.code || ""}
                              onChange={handleChange}
                              size="small"
                              fullWidth
                            />
                          ) : (
                            <Typography
                              variant="body2"
                              sx={{ fontFamily: "monospace" }}
                            >
                              {product.code}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          {editingId === product.id ? (
                            <Stack spacing={1}>
                              <TextField
                                name="unitPrice"
                                value={editedProduct?.unitPrice || ""}
                                onChange={handleChange}
                                size="small"
                                type="number"
                                label="Unit Price"
                                InputProps={{
                                  startAdornment: (
                                    <Typography sx={{ mr: 0.5 }}>$</Typography>
                                  ),
                                }}
                              />
                              <TextField
                                name="casePrice"
                                value={editedProduct?.casePrice || ""}
                                onChange={handleChange}
                                size="small"
                                type="number"
                                label="Case Price"
                                InputProps={{
                                  startAdornment: (
                                    <Typography sx={{ mr: 0.5 }}>$</Typography>
                                  ),
                                }}
                              />
                              <TextField
                                name="itemsInCase"
                                value={editedProduct?.itemsInCase || ""}
                                onChange={handleChange}
                                size="small"
                                type="number"
                                label="Units/Case"
                              />
                            </Stack>
                          ) : (
                            <Box>
                              <Typography
                                variant="body1"
                                color="primary.main"
                                sx={{ fontWeight: "medium" }}
                              >
                                ${product.unitPrice}{" "}
                                <Typography component="span" variant="caption">
                                  per unit
                                </Typography>
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                ${product.casePrice} per case
                              </Typography>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                ({product.itemsInCase} units/case)
                              </Typography>
                            </Box>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {product.category.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Stack direction="row" spacing={1}>
                            {editingId === product.id ? (
                              <>
                                <IconButton
                                  size="small"
                                  onClick={handleSave}
                                  color="primary"
                                  sx={{ bgcolor: "primary.lighter" }}
                                >
                                  <SaveIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={handleCancel}
                                  color="error"
                                  sx={{ bgcolor: "error.lighter" }}
                                >
                                  <CancelIcon fontSize="small" />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                {/* <IconButton
                                  size="small"
                                  component={Link}
                                  to={`/admin/warehouse/inventory-control/edit/${product.id}`}
                                  sx={{ color: "success.main" }}
                                >
                                  <OpenInNewIcon fontSize="small" />
                                </IconButton> */}
                                <IconButton
                                  size="small"
                                  onClick={() => handleEdit(product)}
                                  sx={{ color: "primary.main" }}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => handleDelete(product.id)}
                                  sx={{ color: "error.main" }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => handleToggle(product.id)}
                                  sx={{ color: "info.main" }}
                                >
                                  <SwapHorizIcon fontSize="small" />
                                </IconButton>
                              </>
                            )}
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            size="small"
                            component={Link}
                            to={`/admin/warehouse/inventory-control/edit/${product.id}`}
                            sx={{
                              color: "warning.main",
                              "&:hover": {
                                bgcolor: "warning.lighter",
                              },
                            }}
                            title="Edit Inventory Quantities"
                          >
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Typography variant="body2" color="warning.main">
                                Edit Quantities
                              </Typography>
                              <OpenInNewIcon fontSize="small" />
                            </Stack>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Dialog
              open={deleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
            >
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this product?
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDeleteDialogOpen(false)}>
                  Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="error">
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Pagination
                count={pagination ? pagination.totalPages : 0}
                page={pagination ? pagination.currentPage : 1}
                onChange={handlePageChange}
                color="primary"
                size="large"
              />
            </Box>
          </Box>
        }
      />
      <Route path=":id" element={<InventoryProductDetails />} />
    </Routes>
  );
};

export default observer(InventoryControl);
