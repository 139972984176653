import * as React from "react";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store.ts";
import { useNavigate, useLocation } from "react-router-dom";

const settings = ["Profile", "Logout"];
const AppName = "SALES CATALOGUE";
const navBarHeight = 64; // Standard Material-UI AppBar height in pixels

interface NavBarProps {
  open: boolean;
  width: number;
}

const NavBar: React.FC<NavBarProps> = ({ open, width }) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const { userStore, cartStore } = useStore();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route

  const handleOpenUserMenu = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    },
    []
  );

  const handleCloseUserMenu = React.useCallback(() => {
    setAnchorElUser(null);
  }, []);

  const handleCartBtnClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      navigate("/user/cart");
    },
    [navigate]
  );

  const UserMenu = ({ anchorEl, handleClose }) => (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {settings.map((setting) => (
        <MenuItem
          key={setting}
          onClick={() => {
            handleClose();
            if (setting === "Logout") {
              userStore.logout();
            } else if (setting === "Profile") {
              // Conditionally navigate based on user's role
              if (userStore.user?.role === "Admin") {
                navigate("/admin/profile"); // Navigate to admin profile page
              } else {
                navigate("/user/profile"); // Navigate to user profile page
              }
            }
          }}
        >
          {setting}
        </MenuItem>
      ))}
    </Menu>
  );

  // Check if the current route is AddItemsToOrder page
  const isAddItemsToOrderPage = location.pathname.includes("/addorderitem");

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${width}px)`,
        ml: `${width}px`,
        height: navBarHeight,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        transition: theme => theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }}
    >
      <Toolbar>
        <Box display="flex" alignItems="center">
          <img 
            src={`${process.env.PUBLIC_URL}/LOGO_2048x.png`}
            alt="logo" 
            style={{ 
              height: '48px', 
              width: 'auto', 
              marginRight: '16px', 
              objectFit: 'contain', 
              filter: 'brightness(0) invert(1)', 
            }} 
          />
          <Typography
            variant="h6"
            noWrap
            component="div"
            onClick={() => navigate('/')}
            sx={{
              mr: 3,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              cursor: 'pointer',
            }}
          >
            {AppName}
          </Typography>
        </Box>

        <Box
          display="flex"
          ml="auto"
          alignItems="center"
          sx={{ flexGrow: 0 }}
        >
          {userStore.user &&
            userStore.user.role !== "Admin" &&
            !isAddItemsToOrderPage && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Cart">
                  <IconButton
                    size="large"
                    color="inherit"
                    aria-label="4 items in Cart"
                    sx={{ p: 0, mr: 3 }}
                    onClick={handleCartBtnClick}
                  >
                    <Badge badgeContent={cartStore.totalItems} color="error">
                      <ShoppingCartIcon sx={{ mr: 0.5 }} />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={userStore.user?.displayName}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={userStore.user?.displayName}
                  src="/static/images/avatar/2.jpg"
                />
              </IconButton>
            </Tooltip>
            <UserMenu
              anchorEl={anchorElUser}
              handleClose={handleCloseUserMenu}
            />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default observer(NavBar);
