export interface IUser {
  userName: string;
  displayName: string;
  token: string;
  role: string;
  imageUrl?: string;
}

export interface ILoginFormValues {
  userName: string;
  password: string;
  // role: string;
}

export interface IAppUser {
  userName: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  isActive: boolean;
  bio?: string;
  password?: string;
}

export class AppUser implements IAppUser {
  userName: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  isActive: boolean;
  bio: string;
  password?: string;
  role?: string;

  constructor(init: AppUserFormValues) {
    Object.assign(this, init);
  }
}

export class AppUserFormValues {
  userName: string = "";
  displayName: string = "";
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  bio: string = "";
  PasswordHash?: string = "";
  role?: string = "";

  constructor(user?: AppUserFormValues) {
    if (user) {
      this.userName = user.userName;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.displayName = user.displayName;
      this.email = user.email;
      this.bio = user.bio ?? "";
      this.PasswordHash = user.PasswordHash ?? "";
      this.role = user.role ?? "";
    }
  }
}
