import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Pagination,
  Box,
  CircularProgress,
  Button,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Grid,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../app/utils/dateUtils.js";
import { OrderStatus } from "../../../../app/models/order.ts";
import agent from '../../../../app/api/agent.ts';
import { debounce } from 'lodash';
import ClearIcon from "@mui/icons-material/Clear";
import { AppUser } from "../../../../app/models/user.ts";

const OrderList: React.FC = () => {
  const { orderStore } = useStore();
  const {
    setPagingParams,
    loadOrdersToPrepareShipment,
    pagination,
    orders,
    pagingParams,
    loadingInitial,
    clientFilter,
    orderNumberFilter,
    setClientFilter,
    setOrderNumberFilter,
    clients,
    loadActiveClients,
    userFilter,
    setUserFilter,
    users,
    loadActiveUsers,
  } = orderStore;

  const [filters, setFilters] = useState({
    client: clientFilter,
    orderNumber: orderNumberFilter,
    user: userFilter
  });
  const [isFiltering, setIsFiltering] = useState(false);
  const [isAssigning, setIsAssigning] = useState<Record<number, boolean>>({});
  const [alerts, setAlerts] = useState({
    error: null as string | null,
    success: null as string | null
  });
  const [outOfStockDialog, setOutOfStockDialog] = useState({
    open: false,
    items: [] as any[]
  });

  useEffect(() => {
    loadActiveUsers();
    loadActiveClients();
  }, [loadActiveUsers, loadActiveClients]);

  const debouncedApplyFilters = useCallback(
    debounce(async () => {
      try {
        setClientFilter(filters.client);
        setUserFilter(filters.user);
        setOrderNumberFilter(filters.orderNumber);
        setPagingParams({ ...pagingParams, pageNumber: 1 });
        await loadOrdersToPrepareShipment();
      } finally {
        setIsFiltering(false);
      }
    }, 500),
    [
      filters,
      setPagingParams,
      pagingParams,
      setClientFilter,
      setUserFilter,
      setOrderNumberFilter,
      loadOrdersToPrepareShipment
    ]
  );

  useEffect(() => {
    loadOrdersToPrepareShipment();
  }, [loadOrdersToPrepareShipment]);

  useEffect(() => {
    if (filters.client !== clientFilter || 
        filters.orderNumber !== orderNumberFilter ||
        filters.user !== userFilter) {
      setIsFiltering(true);
      debouncedApplyFilters();
    }
    
    return () => {
      debouncedApplyFilters.cancel();
    };
  }, [filters, clientFilter, orderNumberFilter, userFilter, debouncedApplyFilters]);

  const navigateToHome = useCallback((pageNumber: number) => {
    setPagingParams({
      pageNumber: pageNumber,
      pageSize: pagingParams.pageSize,
    });
    loadOrdersToPrepareShipment();
  }, [setPagingParams, pagingParams.pageSize, loadOrdersToPrepareShipment]);

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => navigateToHome(value);

  // const handleStatusChange = async (orderId: number, newStatus: OrderStatus) => {
  //   await updateOrderStatus(orderId, newStatus);
  //   loadOrdersToPrepareShipment();
  // };

  const handleFilterChange = (type: 'client' | 'user' | 'orderNumber', value: string) => {
    setFilters(prev => ({ ...prev, [type]: value }));
    setIsFiltering(true);
  };

  const handleAssignLots = useCallback(async (orderId: number) => {
    if (isAssigning[orderId]) return;
    
    try {
      setIsAssigning(prev => ({ ...prev, [orderId]: true }));
      
      const order = orders.find(o => o.id === orderId);
      if (order?.status === OrderStatus.OutOfStock) {
        const stockCheckResult = await orderStore.checkOrderStock(orderId);
        
        if (!stockCheckResult.success) {
          setOutOfStockDialog({
            open: true,
            items: stockCheckResult.outOfStockItems
          });
          return;
        }
      }
      
      await agent.Orders.assignLots(orderId);
      setAlerts(prev => ({ ...prev, success: 'Lots assigned successfully' }));
      loadOrdersToPrepareShipment();
    } catch (error: unknown) {      
      const errorMessage = error instanceof Error && 'response' in error 
        ? (error as any).response?.data || 'An error occurred while assigning lots'
        : 'An error occurred while assigning lots';
      setAlerts(prev => ({ ...prev, error: errorMessage }));
    } finally {
      setIsFiltering(false);
      setIsAssigning(prev => ({ ...prev, [orderId]: false }));
    }
  }, [isAssigning, orders, orderStore, loadOrdersToPrepareShipment]);

  const handleCloseDialog = () => {
    setOutOfStockDialog({
      open: false,
      items: []
    });
  };

  if (loadingInitial) return <CircularProgress />;

  return (
    <Box sx={{ p: 2 }}>
      {/* Updated Filters Section */}
      <Paper 
        elevation={1} 
        sx={{ 
          p: 2, 
          mb: 3,
          borderRadius: 2,
          bgcolor: 'background.paper'
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Client</InputLabel>
              <Select
                value={filters.client}
                onChange={(e) => handleFilterChange('client', e.target.value)}
                label="Client"
              >
                <MenuItem value="">All Clients</MenuItem>
                {clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel>User</InputLabel>
              <Select
                value={filters.user || ''}
                onChange={(e) => handleFilterChange('user', e.target.value as string)}
                label="User"
              >
                <MenuItem value="">All Users</MenuItem>
                {users.map((user: AppUser) => (
                  <MenuItem key={user.userName} value={user.userName}>
                    {user.userName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              size="small"
              label="Order Number"
              value={filters.orderNumber}
              onChange={(e) => handleFilterChange('orderNumber', e.target.value)}
              InputProps={{
                endAdornment: filters.orderNumber && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => handleFilterChange('orderNumber', '')}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {isFiltering && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <CircularProgress size={20} sx={{ mr: 1 }} />
                <Typography variant="body2" color="text.secondary">
                  Applying filters...
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>

      {/* Alerts */}
      <Snackbar 
        open={!!alerts.error} 
        autoHideDuration={6000} 
        onClose={() => setAlerts(prev => ({ ...prev, error: null }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setAlerts(prev => ({ ...prev, error: null }))} 
          severity="error" 
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alerts.error}
        </Alert>
      </Snackbar>

      <Snackbar 
        open={!!alerts.success} 
        autoHideDuration={3000} 
        onClose={() => setAlerts(prev => ({ ...prev, success: null }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setAlerts(prev => ({ ...prev, success: null }))} 
          severity="success" 
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alerts.success}
        </Alert>
      </Snackbar>

      {/* Table Section */}
      <Paper 
        elevation={2} 
        sx={{ 
          borderRadius: 2,
          overflow: 'hidden'
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="medium">
            <TableHead>
              <TableRow sx={{ 
                bgcolor: (theme) => theme.palette.primary.main,
              }}>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Order Number
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Order Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Client
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    User
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Sub Total
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Items
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow
                  key={order.id}
                  sx={{
                    '&:hover': { 
                      bgcolor: 'action.hover',
                      transition: 'background-color 0.2s ease'
                    },
                    borderBottom: '1px solid',
                    borderColor: 'divider'
                  }}
                >
                  <TableCell>
                    <Typography variant="body2" sx={{ fontFamily: 'monospace', fontWeight: 'medium' }}>
                      {order.orderNumber}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {formatDate(order.orderDate)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                      {order.client.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {order.userName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="primary.main" sx={{ fontWeight: 'medium' }}>
                      ${order.subTotal}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {order.itemsCount}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={OrderStatus[order.status]}
                      color={order.status === OrderStatus.Approved ? "success" : 
                             order.status === OrderStatus.OutOfStock ? "error" : "default"}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton 
                      component={Link} 
                      to={`/admin/warehouse/prepare-shipment/${order.id}`}
                      disabled={order.status === OrderStatus.Approved || order.status === OrderStatus.OutOfStock}
                      size="small"
                      sx={{ 
                        color: 'primary.main',
                        '&.Mui-disabled': {
                          color: 'action.disabled'
                        }
                      }}
                    >
                      <OpenInNewIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={isAssigning[order.id] ? 
                        <CircularProgress size={20} color="inherit" /> : 
                        <AssignmentIcon />
                      }
                      onClick={() => handleAssignLots(order.id)}
                      sx={{
                        minWidth: '130px',
                        bgcolor: 'primary.main',
                        '&:hover': {
                          bgcolor: 'primary.dark',
                        }
                      }}
                    >
                      {isAssigning[order.id] ? 'Reassigning...' : 'Reassign Products'}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Pagination
          count={pagination ? pagination.totalPages : 0}
          page={pagination ? pagination.currentPage : 1}
          onChange={handlePageChange}
          color="primary"
          size="large"
        />
      </Box>

      {/* Out of Stock Dialog - keeping existing styling as it looks good */}
      <Dialog 
        open={outOfStockDialog.open} 
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle 
          sx={{ 
            bgcolor: '#525252', 
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="h6">
              Insufficient Stock Alert
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
            The following items don't have sufficient stock to fulfill the order:
          </Typography>
          <List>
            {outOfStockDialog.items.map((item, index) => (
              <ListItem 
                key={index}
                sx={{
                  mb: 2,
                  border: '1px solid #e0e0e0',
                  borderRadius: 1,
                  backgroundColor: '#f8f8f8'
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1
                  }}>
                    <Typography variant="h6" color="primary">
                      {item.product.name}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      Code: {item.product.code}
                    </Typography>
                  </Box>
                  
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={4}>
                      <Paper 
                        elevation={0} 
                        sx={{ 
                          p: 1.5, 
                          backgroundColor: '#e3f2fd',
                          textAlign: 'center'
                        }}
                      >
                        <Typography variant="subtitle2" color="text.secondary">
                          Requested
                        </Typography>
                        <Typography variant="h6" color="primary">
                          {item.quantity}
                          <Typography component="span" variant="body2" sx={{ ml: 0.5 }}>
                            {item.byCase ? 'cases' : 'units'}
                          </Typography>
                        </Typography>
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={4}>
                      <Paper 
                        elevation={0} 
                        sx={{ 
                          p: 1.5, 
                          backgroundColor: '#fff3e0',
                          textAlign: 'center'
                        }}
                      >
                        <Typography variant="subtitle2" color="text.secondary">
                          Available
                        </Typography>
                        <Typography variant="h6" color="warning.main">
                          {item.availableQuantity}
                          <Typography component="span" variant="body2" sx={{ ml: 0.5 }}>
                            {item.byCase ? 'cases' : 'units'}
                          </Typography>
                        </Typography>
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={4}>
                      <Paper 
                        elevation={0} 
                        sx={{ 
                          p: 1.5, 
                          backgroundColor: '#ffebee',
                          textAlign: 'center'
                        }}
                      >
                        <Typography variant="subtitle2" color="text.secondary">
                          Shortage
                        </Typography>
                        <Typography variant="h6" color="error">
                          {item.quantity - item.availableQuantity}
                          <Typography component="span" variant="body2" sx={{ ml: 0.5 }}>
                            {item.byCase ? 'cases' : 'units'}
                          </Typography>
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, borderTop: '1px solid #e0e0e0' }}>
          <Button 
            onClick={handleCloseDialog} 
            variant="contained"
            size="large"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default observer(OrderList);
