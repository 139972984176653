import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useStore } from "../../app/stores/store.ts";

import { AppUserFormValues } from "../../app/models/user.ts";
import { useNavigate, useParams } from "react-router-dom";
import MyTextInput from "../../app/common/form/MyTextInput.tsx";
import { Link } from "react-router-dom";

const enum DISPLAY_NAMES {
  userName = "UserName",
  firstName = "First Name",
  lastName = "Last Name",
  email = "Email",
  bio = "Bio",
  password = "Password",
}

const enum FIELD_NAMES {
  userName = "userName",
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  bio = "bio",
  password = "PasswordHash",
}

const ProfileForm: React.FC = () => {
  const navigate = useNavigate();
  const { userStore, appUserStore } = useStore();
  const [profile, setProfile] = useState(userStore.profileUser);
  const userRole = userStore.user?.role;
  const {
    editMode,
    pagingParams,
    loadingInitial,
    setPagingParams,
    setEditMode,
    updateProfileAppUser,
  } = appUserStore;
  const [appUser, setAppUser] = useState<AppUserFormValues>({
    userName: "",
    firstName: "",
    lastName: "",
    displayName: "",
    email: "",
    bio: "",
    PasswordHash: "",
  });
  const { username } = useParams();

  const navigateToHome = () => {
    setPagingParams({
      pageNumber: 1,
      pageSize: pagingParams.pageSize,
    });
    const path = userRole === "Admin" ? "/admin/profile" : "/user/profile";
    navigate(path);
  };

  useEffect(() => {
    if (username) {
      userStore.getUserProfile(username);
    }
  }, [username]);

  useEffect(() => {
    if (userStore.profileUser) {
      setAppUser({
        userName: userStore.profileUser.userName,
        firstName: userStore.profileUser.firstName,
        lastName: userStore.profileUser.lastName,
        displayName: userStore.profileUser.displayName,
        email: userStore.profileUser.email,
        bio: userStore.profileUser.bio || "",
        PasswordHash: "",
      });
    }
  }, [userStore.profileUser]);

  const validationSchema = Yup.object({
    userName: Yup.string().required("Required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    bio: Yup.string(),
    PasswordHash: Yup.string()
      .nullable()
      .min(6, "Password must be at least 6 characters long")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
      ),
  });

  const handleFormSubmit = (values: AppUserFormValues) => {
    const updatedValues = {
      ...values,
      displayName: `${values.firstName} ${values.lastName}`,
    };
    updateProfileAppUser(updatedValues).then(() => navigateToHome());
  };

  if (!profile) return <CircularProgress />;

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Edit Profile
      </Typography>
      <Formik
        initialValues={appUser}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleFormSubmit(values);
        }}
      >
        {({ isSubmitting, isValid, dirty, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} direction="column">
              <Grid item>
                <MyTextInput
                  name={FIELD_NAMES.userName}
                  label={DISPLAY_NAMES.userName}
                  disabled={true}
                />
              </Grid>
              <Grid item>
                <MyTextInput
                  name={FIELD_NAMES.firstName}
                  label={DISPLAY_NAMES.firstName}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <MyTextInput
                  name={FIELD_NAMES.lastName}
                  label={DISPLAY_NAMES.lastName}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <MyTextInput
                  name={FIELD_NAMES.email}
                  label={DISPLAY_NAMES.email}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <MyTextInput
                  name={FIELD_NAMES.password}
                  label={DISPLAY_NAMES.password}
                  type="password"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <MyTextInput
                  name={FIELD_NAMES.bio}
                  label={DISPLAY_NAMES.bio}
                  fullWidth
                  rows={3}
                />
              </Grid>

              <Grid item container spacing={8}>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || !dirty || !isValid}
                  >
                    Save
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    component={Link}
                    to={userRole === "Admin" ? `/admin/home` : `/user/home`}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default observer(ProfileForm);
