import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import * as Yup from "yup";
import { AppUserFormValues } from "../../../../app/models/user.ts";
import { Link, useNavigate, useParams } from "react-router-dom";
import MyTextInput from "../../../../app/common/form/MyTextInput.tsx";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";

const enum DISPLAY_NAMES {
  userName = "UserName",
  firstName = "First Name",
  lastName = "Last Name",
  email = "Email",
  bio = "Bio",
  password = "Password",
  role = "Role",
}

const enum FIELD_NAMES {
  userName = "userName",
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  bio = "bio",
  password = "PasswordHash",
  role = "role",
}

const AppUserForm: React.FC = () => {
  const navigate = useNavigate();
  const { appUserStore } = useStore();
  const {
    editMode,
    pagingParams,
    loadingInitial,
    setPagingParams,
    setEditMode,
    loadAppUserByUserName,
    updateAppUser,
    saveAppUser,
  } = appUserStore;

  const [appUser, setAppUser] = useState<AppUserFormValues | null>(null);
  const [error, setError] = useState<string | null>(null); // To store backend error message
  const { username } = useParams();

  const navigateToHome = () => {
    setPagingParams({
      pageNumber: 1,
      pageSize: pagingParams.pageSize,
    });
    navigate("/admin/users");
  };

  const handleCreateOrEditAppUser = async (appUser: AppUserFormValues) => {
    const updatedValues = {
      ...appUser,
      displayName: `${appUser.firstName} ${appUser.lastName}`,
    };

    try {
      // Perform the API call based on whether it's edit or create mode
      const action = editMode
        ? await updateAppUser(updatedValues)
        : await saveAppUser(updatedValues);

      // If successful, navigate to the users page
      navigateToHome();
    } catch (error: any) {
      // Handle the error, especially duplicate username
      // Assuming error.response.data.message is where the backend sends the error message

      setError("A user with this username already exists.");
    }
  };

  useEffect(() => {
    if (username) {
      loadAppUserByUserName(username).then((appUser) => {
        setAppUser(new AppUserFormValues(appUser));
      });
      setEditMode(true);
    } else {
      setAppUser(new AppUserFormValues());
      setEditMode(false);
    }
  }, [username, loadAppUserByUserName, setEditMode]);

  const validationSchema = Yup.object({
    userName: Yup.string().required("Required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    bio: Yup.string(),
    PasswordHash: editMode
      ? Yup.string()
          .nullable()
          .min(6, "Password must be at least 6 characters long")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
            "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
          )
      : Yup.string()
          .required("Required")
          .min(6, "Password must be at least 6 characters long")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
            "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
          ),
    role: Yup.string().required("Role is required"),
  });

  if (editMode && (loadingInitial || !appUser)) return <CircularProgress />;

  return (
    appUser && (
      <Box sx={{ padding: 2 }}>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          {editMode ? "Edit User" : "Add User"}
        </Typography>

        {/* Display the error message if there is one */}
        {error && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={() => setError(null)}
          >
            <Alert
              onClose={() => setError(null)}
              severity="error"
              sx={{ width: "100%" }}
            >
              {error}
            </Alert>
          </Snackbar>
        )}

        <Formik
          initialValues={appUser}
          validationSchema={validationSchema}
          onSubmit={(values) => handleCreateOrEditAppUser(values)}
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            setFieldValue,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} direction="column">
                <Grid item>
                  <MyTextInput
                    name={FIELD_NAMES.userName}
                    label={DISPLAY_NAMES.userName}
                    disabled={editMode}
                  />
                </Grid>
                <Grid item>
                  <MyTextInput
                    name={FIELD_NAMES.firstName}
                    label={DISPLAY_NAMES.firstName}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <MyTextInput
                    name={FIELD_NAMES.lastName}
                    label={DISPLAY_NAMES.lastName}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <MyTextInput
                    name={FIELD_NAMES.email}
                    label={DISPLAY_NAMES.email}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    select
                    name={FIELD_NAMES.role}
                    label={DISPLAY_NAMES.role}
                    fullWidth
                    value={values.role || ""}
                    onChange={(e) =>
                      setFieldValue(FIELD_NAMES.role, e.target.value)
                    }
                    sx={{ width: "45%" }}
                  >
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="User">User</MenuItem>
                    <MenuItem value="WHEmployee">Warehouse Employee</MenuItem>
                  </TextField>
                </Grid>
                <Grid item>
                  <MyTextInput
                    name={FIELD_NAMES.password}
                    label={DISPLAY_NAMES.password}
                    type="password"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <MyTextInput
                    name={FIELD_NAMES.bio}
                    label={DISPLAY_NAMES.bio}
                    fullWidth
                    rows={3}
                  />
                </Grid>

                <Grid item container spacing={8}>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !dirty || !isValid}
                    >
                      {editMode ? "Save" : "Add"}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      color="error"
                      component={Link}
                      to={`/admin/users`}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    )
  );
};

export default observer(AppUserForm);
