import React from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  IconButton,
  Typography,
  Select,
  MenuItem,
  Grid,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useStore } from "../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

const CartDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { cartStore, commonStore, orderStore } = useStore();
  const { selectedClient, isOrderMode } = commonStore;
  const {
    cartItems,
    totalItems,
    totalPrice,
    updateItemQuantity,
    removeItemFromCart,
  } = cartStore;
  const { saveOrder, createOrderModel } = orderStore;

  // const totalPrice = cartStore.cartItems.reduce(
  //   (total, item) => total + item.price * item.quantity,
  //   0
  // );

  const handleQuantityChange = (id: number, quantity: number) => {
    updateItemQuantity(id, quantity);
  };

  const handleRemoveItem = (id: number) => {
    removeItemFromCart(id);
  };

  const handleCreateOrder = async () => {
    const newOrder = createOrderModel(cartItems, selectedClient!);
    await saveOrder(newOrder);
    cartStore.clearCart();
    commonStore.setOrderMode(false);
    commonStore.setSelectedClient(null);
    navigate("/user/myorders");
  };

  return isOrderMode ? (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>Shopping Cart</Typography>
        <Typography variant="h6" color="primary" gutterBottom>
          Client: {selectedClient?.name}
        </Typography>
      </Grid>
      
      {cartItems.length === 0 ? (
        <Grid item xs={12}>
          <Card sx={{ p: 4, textAlign: 'center' }}>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Your cart is empty
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/user/placeorder')}
              sx={{ mt: 2 }}
            >
              Browse Products
            </Button>
          </Card>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} md={9}>
            {cartItems.map((item) => (
              <Card
                key={item.id}
                sx={{
                  display: "flex",
                  mb: 2,
                  p: 3,
                  maxWidth: "100%",
                  transition: 'all 0.2s',
                  '&:hover': {
                    boxShadow: 3
                  }
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ width: 120, height: 120, objectFit: 'contain' }}
                  image={item.product.imageUrl}
                  alt={item.product.name}
                />
                <Box sx={{ flexGrow: 1, ml: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <Typography variant="h6" gutterBottom>
                    {item.product.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Sold by: {item.byCase ? "Case" : "Unit"}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Select
                      value={item.quantity}
                      onChange={(event) =>
                        handleQuantityChange(item.id, event.target.value as number)
                      }
                      size="small"
                      sx={{ width: 80, mr: 2 }}
                    >
                      {[...Array(30).keys()].map((value) => (
                        <MenuItem key={value} value={value + 1}>
                          {value + 1}
                        </MenuItem>
                      ))}
                    </Select>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleRemoveItem(item.id)}
                      color="error"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', ml: 2 }}>
                  <Typography variant="h6" color="primary">
                    ${item.price.toFixed(2)}
                  </Typography>
                </Box>
              </Card>
            ))}
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ p: 3, position: 'sticky', top: 20 }}>
              <Typography variant="h6" gutterBottom>Order Summary</Typography>
              <Box sx={{ my: 2 }}>
                <Box display="flex" justifyContent="space-between" mb={1}>
                  <Typography color="text.secondary">Items ({totalItems}):</Typography>
                  <Typography>${totalPrice.toFixed(2)}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Typography color="text.secondary">Shipping:</Typography>
                  <Typography>Free</Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Typography variant="h6">Total:</Typography>
                  <Typography variant="h6" color="primary">
                    ${totalPrice.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={handleCreateOrder}
              >
                Create Order
              </Button>
            </Card>
          </Grid>
        </>
      )}
    </Grid>
  ) : (
    <Card sx={{ p: 4, textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom>
        Please Select a Client
      </Typography>
      <Typography color="text.secondary" gutterBottom>
        Go to Place Order page to select a client and start shopping
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/user/placeorder')}
        sx={{ mt: 2 }}
      >
        Place Order
      </Button>
    </Card>
  );
};

export default observer(CartDashboard);
