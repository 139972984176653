import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Chip,
  IconButton,
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
  Pagination,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useStore } from '../../../../app/stores/store.ts';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../../app/utils/dateUtils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ClearIcon from '@mui/icons-material/Clear';
import { debounce } from 'lodash';

enum ReturnStatus {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
  Processed = "Processed"
}

const ReturnManagement: React.FC = () => {
  const navigate = useNavigate();
  const { returnStore } = useStore();
  const [returns, setReturns] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    orderNumber: '',
    status: '',
    client: ''
  });
  const [isFiltering, setIsFiltering] = useState(false);
  const [alerts, setAlerts] = useState({
    success: null as string | null,
    error: null as string | null
  });

  // Pagination state
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    pageSize: 10
  });

  const handleFilterChange = (type: 'orderNumber' | 'status' | 'client', value: string) => {
    setFilters(prev => ({ ...prev, [type]: value }));
    setIsFiltering(true);
  };

  const debouncedApplyFilters = debounce(async () => {
    try {
      // Implement filter logic here
      setIsFiltering(false);
    } catch (error) {
      console.error('Error applying filters:', error);
      setIsFiltering(false);
    }
  }, 500);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPagination(prev => ({ ...prev, currentPage: value }));
  };

  const handleProcessReturn = async (returnId: number) => {
    try {
      // Implement return processing logic
      setAlerts({ ...alerts, success: 'Return processed successfully' });
    } catch (error) {
      setAlerts({ ...alerts, error: 'Failed to process return' });
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Filters Section */}
      <Paper elevation={2} sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Return Management
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              size="small"
              label="Order Number"
              value={filters.orderNumber}
              onChange={(e) => handleFilterChange('orderNumber', e.target.value)}
              InputProps={{
                endAdornment: filters.orderNumber && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => handleFilterChange('orderNumber', '')}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Status</InputLabel>
              <Select
                value={filters.status}
                label="Status"
                onChange={(e) => handleFilterChange('status', e.target.value)}
              >
                <MenuItem value="">All</MenuItem>
                {Object.values(ReturnStatus).map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      {/* Returns Table */}
      <Paper elevation={2} sx={{ borderRadius: 2, overflow: 'hidden' }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="medium">
            <TableHead>
              <TableRow sx={{ bgcolor: theme => theme.palette.primary.main }}>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Return ID
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Order Number
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Return Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Client
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {returns.map((returnItem) => (
                <TableRow key={returnItem.id}>
                  <TableCell>{returnItem.id}</TableCell>
                  <TableCell>{returnItem.orderNumber}</TableCell>
                  <TableCell>{formatDate(returnItem.returnDate)}</TableCell>
                  <TableCell>
                    <Chip
                      label={returnItem.status}
                      color={
                        returnItem.status === ReturnStatus.Approved ? "success" :
                        returnItem.status === ReturnStatus.Rejected ? "error" :
                        returnItem.status === ReturnStatus.Processed ? "info" : "default"
                      }
                      size="small"
                    />
                  </TableCell>
                  <TableCell>{returnItem.clientName}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <IconButton
                        size="small"
                        onClick={() => navigate(`/admin/warehouse/returns/${returnItem.id}`)}
                        sx={{ color: 'info.main' }}
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                      {returnItem.status === ReturnStatus.Approved && (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => handleProcessReturn(returnItem.id)}
                        >
                          Process Return
                        </Button>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Pagination */}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Pagination
          count={pagination.totalPages}
          page={pagination.currentPage}
          onChange={handlePageChange}
          color="primary"
          size="large"
        />
      </Box>

      {/* Notifications */}
      <Snackbar
        open={!!alerts.error}
        autoHideDuration={6000}
        onClose={() => setAlerts(prev => ({ ...prev, error: null }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setAlerts(prev => ({ ...prev, error: null }))}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alerts.error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!alerts.success}
        autoHideDuration={3000}
        onClose={() => setAlerts(prev => ({ ...prev, success: null }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setAlerts(prev => ({ ...prev, success: null }))}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alerts.success}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default observer(ReturnManagement); 