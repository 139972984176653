import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Pagination,
  Box,
  CircularProgress,
  Button,
  Alert,
  Snackbar,
  TextField,
  IconButton,
  Grid,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import ShipmentIcon from "@mui/icons-material/LocalShipping";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { formatDate } from "../../../../app/utils/dateUtils.js";
import { OrderStatus } from "../../../../app/models/order.ts";
import agent from "../../../../app/api/agent.ts";
import { useNavigate } from "react-router-dom";
import { debounce } from 'lodash';
import ClearIcon from "@mui/icons-material/Clear";

const ShipManagement: React.FC = () => {
  const { orderStore } = useStore();
  const {
    setPagingParams,
    loadOrdersForShipment,
    pagination,
    orders,
    pagingParams,
    setOrders,
    clientFilter,
    orderNumberFilter,
    setClientFilter,
    setOrderNumberFilter,
    clients,
    loadActiveClients,
    userFilter,
    setUserFilter,
    users,
    loadActiveUsers,
  } = orderStore;

  const navigateToHome = (pageNumber: number) => {
    setPagingParams({
      pageNumber: pageNumber,
      pageSize: pagingParams.pageSize,
    });
    loadOrdersForShipment();
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => navigateToHome(value);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isShipping, setIsShipping] = useState<{ [key: number]: boolean }>({});

  const navigate = useNavigate();

  const handleShipOrder = async (orderId: number) => {
    setIsShipping((prev) => ({ ...prev, [orderId]: true }));
    try {
      const shipmentDate = new Date();
      await agent.Orders.updateOrderStatus(orderId, OrderStatus.Shipped);
      await agent.Orders.updateShipmentDate(orderId, shipmentDate);

      setOrders(
        orders.map((order) =>
          order.id === orderId
            ? {
                ...order,
                status: OrderStatus.Shipped,
                shipmentDate: shipmentDate,
              }
            : order
        )
      );
      setSuccessMessage("Order shipped successfully");
    } catch (error) {
      console.error("Error shipping order:", error);
      setErrorMessage("Failed to ship order");
    } finally {
      setIsShipping((prev) => ({ ...prev, [orderId]: false }));
    }
  };

  useEffect(() => {
    loadOrdersForShipment();
  }, [loadOrdersForShipment]);

  const [localClientFilter, setLocalClientFilter] = useState(clientFilter);
  const [localOrderNumberFilter, setLocalOrderNumberFilter] = useState(orderNumberFilter);
  const [isFiltering, setIsFiltering] = useState(false);
  const [localUserFilter, setLocalUserFilter] = useState(userFilter);

  useEffect(() => {
    loadActiveUsers();
    loadActiveClients();
  }, [loadActiveUsers, loadActiveClients]);

  const applyFilters = useCallback(
    debounce(() => {
      setClientFilter(localClientFilter);
      setOrderNumberFilter(localOrderNumberFilter);
      setUserFilter(localUserFilter);
      setPagingParams({ ...pagingParams, pageNumber: 1 });
      loadOrdersForShipment();
      setIsFiltering(false);
    }, 500),
    [localClientFilter, localOrderNumberFilter, localUserFilter]
  );

  useEffect(() => {
    setIsFiltering(true);
    applyFilters();
  }, [localClientFilter, localOrderNumberFilter, localUserFilter]);

  return (
    <Box sx={{ p: 2 }}>
      <Paper 
        elevation={1} 
        sx={{ 
          p: 2, 
          mb: 3,
          borderRadius: 2,
          bgcolor: 'background.paper'
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel>Client</InputLabel>
              <Select
                value={localClientFilter}
                onChange={(e) => setLocalClientFilter(e.target.value)}
                label="Client"
              >
                <MenuItem value="">All Clients</MenuItem>
                {clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel>User</InputLabel>
              <Select
                value={localUserFilter || ''}
                onChange={(e) => setLocalUserFilter(e.target.value as string)}
                label="User"
              >
                <MenuItem value="">All Users</MenuItem>
                {users.map((user) => (
                  <MenuItem key={user.userName} value={user.userName}>
                    {user.userName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              size="small"
              label="Order Number"
              value={localOrderNumberFilter}
              onChange={(e) => setLocalOrderNumberFilter(e.target.value)}
              InputProps={{
                endAdornment: localOrderNumberFilter && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => setLocalOrderNumberFilter('')}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {isFiltering && (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <CircularProgress size={20} sx={{ mr: 1 }} />
                <Typography variant="body2" color="text.secondary">
                  Applying filters...
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(null)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrorMessage(null)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage(null)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessMessage(null)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Paper 
        elevation={2} 
        sx={{ 
          borderRadius: 2,
          overflow: 'hidden'
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="medium">
            <TableHead>
              <TableRow sx={{ 
                bgcolor: (theme) => theme.palette.primary.main,
              }}>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Order Number
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Order Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Client
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Sales Rep
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Sub Total
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Items
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Shipment Date
                  </Typography>
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow
                  key={order.id}
                  sx={{
                    '&:hover': { 
                      bgcolor: 'action.hover',
                      transition: 'background-color 0.2s ease'
                    },
                    borderBottom: '1px solid',
                    borderColor: 'divider'
                  }}
                >
                  <TableCell>
                    <Typography variant="body2" sx={{ fontFamily: 'monospace', fontWeight: 'medium' }}>
                      {order.orderNumber}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {formatDate(order.orderDate)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                      {order.client.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {order.userName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="primary.main" sx={{ fontWeight: 'medium' }}>
                      ${order.subTotal}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {order.itemsCount}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={OrderStatus[order.status]}
                      color={order.status === OrderStatus.Shipped ? "success" : "default"}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {order.shipmentDate ? formatDate(order.shipmentDate) : "Not Available"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {order.status === OrderStatus.WaitingToShip && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={isShipping[order.id] ? 
                          <CircularProgress size={20} color="inherit" /> : 
                          <ShipmentIcon />
                        }
                        onClick={() => handleShipOrder(order.id)}
                        disabled={isShipping[order.id]}
                        sx={{
                          minWidth: '120px',
                          bgcolor: 'primary.main',
                          '&:hover': {
                            bgcolor: 'primary.dark',
                          }
                        }}
                      >
                        {isShipping[order.id] ? 'Shipping...' : 'Ship Order'}
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => navigate(`/admin/warehouse/view-shipment/${order.id}`)}
                      sx={{ 
                        color: 'info.main',
                        '&:hover': {
                          bgcolor: 'info.lighter',
                        }
                      }}
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Pagination
          count={pagination ? pagination.totalPages : 0}
          page={pagination ? pagination.currentPage : 1}
          onChange={handlePageChange}
          color="primary"
          size="large"
        />
      </Box>
    </Box>
  );
};

export default observer(ShipManagement);