export interface InventoryStats {
    totalProducts: number;
    normalStock: number;
    lowStock: LowStockProductDto[];
    criticalStock: LowStockProductDto[];
    nearExpiry: ExpiringProductDto[];
    stockTrends: { [key: string]: number };
}

export interface LowStockProductDto {
    id: number;
    name: string;
    code: string;
    quantity: number;
    minimumStock: number;
    lastRestocked: string;
}

export interface ExpiringProductDto {
    id: number;
    name: string;
    expiryDate: string;
    quantity: number;
    batchNumber: string;
}

export interface InventoryThresholds {
    caseLowStockThreshold: number;
    caseCriticalThreshold: number;
    unitLowStockThreshold: number;
    unitCriticalThreshold: number;
}

export type ExpiryStatusType = 'normal' | 'warning' | 'critical' | 'expired';

export const EXPIRY_STATUS: Record<string, ExpiryStatusType> = {
    NORMAL: 'normal',
    WARNING: 'warning',
    CRITICAL: 'critical',
    EXPIRED: 'expired'
} as const;

export const EXPIRY_COLORS: Record<ExpiryStatusType, {
    light: string;
    border: string;
    text: string;
    badge: string;
}> = {
    normal: {
        light: '#f0f9ff',
        border: '#93c5fd',
        text: '#1e40af',
        badge: '#3b82f6'
    },
    warning: {
        light: '#fef9c3',
        border: '#fde047',
        text: '#854d0e',
        badge: '#eab308'
    },
    critical: {
        light: '#fee2e2',
        border: '#fca5a5',
        text: '#991b1b',
        badge: '#ef4444'
    },
    expired: {
        light: '#7f1d1d',
        border: '#b91c1c',
        text: '#fecaca',
        badge: '#dc2626'
    }
}; 