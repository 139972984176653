import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import CategoryIcon from '@mui/icons-material/Category';
import FactoryIcon from '@mui/icons-material/Factory';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MapIcon from '@mui/icons-material/Map';
import WarehouseIcon from '@mui/icons-material/Warehouse';

export const ADMIN_MENU_ITEMS = [
  { text: "Home", icon: HomeIcon },
  { text: "Users", icon: PersonIcon },
  { text: "Clients", icon: BusinessIcon },
  { text: "Categories", icon: CategoryIcon },
  { text: "Manufacturers", icon: FactoryIcon },
  { text: "Products", icon: InventoryIcon },
  { text: "Orders", icon: ShoppingCartIcon },
  { text: "Taxes", icon: AttachMoneyIcon },
  { text: "Tax Mappings", icon: MapIcon },
  { text: "Warehouse", icon: WarehouseIcon }
];

export const USER_MENU_ITEMS = [
  { text: "Home", icon: HomeIcon },
  { text: "PlaceOrder", icon: ShoppingCartIcon },
  { text: "MyOrders", icon: ReceiptIcon }
];
