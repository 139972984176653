import * as React from "react";
import { Box, Container, CssBaseline } from "@mui/material";
import SideBar from "./SideBar.tsx";
import NavBar from "./NavBar.tsx";
import { Outlet } from "react-router-dom";
import { observer } from "mobx-react-lite";

const drawerWidth = 240;
const collapsedDrawerWidth = 65;
const navBarHeight = 70;

const Dashboard = () => {
  const [isOpen, setIsOpen] = React.useState(true);

  const handleSidebarToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box 
      sx={{ 
        display: "flex",
        minHeight: "100vh",
        overflow: "hidden" // Prevent double scrollbars
      }}
    >
      <CssBaseline />
      <NavBar 
        open={isOpen}
        width={isOpen ? drawerWidth : collapsedDrawerWidth} 
      />
      <SideBar open={isOpen} onToggle={handleSidebarToggle} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          mt: `${navBarHeight}px`,
          width: `calc(100% - ${isOpen ? drawerWidth : collapsedDrawerWidth}px)`,
          height: `calc(100vh - ${navBarHeight}px)`,
          overflow: "auto", // Make main content area scrollable
          transition: theme => theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <Container maxWidth="xl" sx={{ p: 2 }}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};

export default observer(Dashboard);
